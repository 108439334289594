<template>
  <div>
    <!-- user need choose a timing for RSVP -->
    <v-dialog
      v-model="timingDialogHandler"
      persistent
      max-width="400px"
      scrollable
    >
      <v-card
        flat
      >
        <v-card-title>
          {{ $t('whenTimingWillSending') }}
        </v-card-title>
        <v-card-text>
          <SchedulingCards
            :package-id="selectedPackage.id"
            :is-pop-up="true"
          ></SchedulingCards>
        </v-card-text>

        <v-card-actions
          class="pa-5 align-center justify-center"
        >
          <v-btn
            color="primary"
            @click="saveTiming"
          >
            {{ $t('Ok') }}
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="$emit('closeAllDialogs')"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- user choose package with WhatsApp messages -->
    <v-dialog
      v-model="whatsAppDialogHandler"
      width="500"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <div class="me-1">
              <v-img
                contain
                height="30"
                width="30"
                :src="require('@/assets/images/messages/whatsApp.png')"
              ></v-img>
            </div>
            <div
              class="me-1 text-lg font-weight-bold"
              style="word-break: break-word;"
            >
              {{ $t('YouChoseWhatsAppMessage') }}
            </div>
          </div>
        </v-card-title>
        <v-card-text class="mt-0 pt-0 text-lg px-8">
          <div class="d-flex">
            <li style="color:green">
            </li>

            {{ $t('WhatsAppMessageWarning') }}
          </div>
          <div class="d-flex">
            <li style="color:green">
            </li>
            {{ $t('WhatsAppMessageTemplateExplain') }}
          </div>
          <v-textarea
            outlined
            class="px-7 py-2"
            readonly
            :value="computedWhatsAppTemplate"
            no-resize
            style="margin-bottom: 0; height: auto;"
            dense
            hide-details
            :dir="targetLanguage === 'he' || targetLanguage === 'ar' ? 'rtl': 'ltr'"
          >
          </v-textarea>
          <div class="px-7">
            {{ $t('AfterWhatsAppButtonClick') }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center align-center pt-5">
          <v-btn
            color="primary"
            @click="confirmWhatsApp"
          >
            {{ $t('Agree') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- user need agree with his event details -->
    <v-dialog
      v-model="eventDetailsDialogHandler"
      width="600"
    >
      <v-card class="rounded-lg">
        <v-card-actions class="pt-1 pb-0">
          <v-card-title class="font-weight-bold">
            {{ $t('eventSettings') }}
          </v-card-title>
          <v-spacer></v-spacer>

          <v-btn
            icon
            @click="dialogOpen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-text>
          <!-- confirmation for start date of RSVPs -->
          <v-checkbox
            v-model="checkboxStartInvitationDate"
            class="my-2"
            hide-details
            :style="$vuetify.rtl ? 'text-align: right;': 'text-align: left;'"
          >
            <template #label>
              <div
                class="d-flex align-center flex-wrap"
              >
                <span
                  class="me-1"
                >
                  {{ $t('agree_invitation_start_date') }}
                </span>
                <strong>
                  {{ moment($store.state.timingData[0].startFrom).format('DD/MM/YY') }}
                </strong>
              </div>
            </template>
          </v-checkbox>

          <!-- confirmation for messages template -->
          <v-checkbox
            v-model="checkboxMessageTemplate"
            class="my-2"
            hide-details
            :style="$vuetify.rtl ? 'text-align: right;': 'text-align: left;'"
          >
            <template #label>
              <div
                class="d-flex align-center flex-wrap"
              >
                <span
                  class="me-1"
                >
                  {{ $t('agree_message_templates') }}
                </span>
                <strong>
                  {{ $store.state.eventData.languages.map(el => $t(el.lang)).join(', ') }}
                </strong>
              </div>
            </template>
          </v-checkbox>

          <!-- confirmation for call template -->
          <v-checkbox
            v-if="selectedPackage.name === 'premium_package'"
            v-model="checkboxCallTemplate"
            class="my-2"
            hide-details
            :style="$vuetify.rtl ? 'text-align: right;': 'text-align: left;'"
          >
            <template #label>
              <div class="d-flex align-center flex-wrap">
                <span class="me-1">
                  {{ $t('agree_call_templates') }}
                </span>
                <strong>
                  {{ $store.state.eventData.languages.map(el => $t(el.lang)).join(',') }}
                </strong>
              </div>
            </template>
          </v-checkbox>

          <!-- notice if user not mark checkbox -->
          <div
            v-if="confirmationMsg"
            class="red--text"
          >
            {{ $t('confirm_all_fields') }}
          </div>
        </v-card-text>

        <v-card-actions class="text-center align-center justify-center">
          <v-btn
            color="primary"
            class="rounded-lg"
            @click="validateUserConfirm"
          >
            {{ $t('Ok') }}
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="rounded-lg"
            @click="$emit('closeAllDialogs')"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import moment from 'moment'
import SchedulingCards from './EventDashboard/SchedulingCards.vue'

export default {
  name: 'CheckBeforePay',
  components: { SchedulingCards },
  props: {
    isDialogOpenBeforePay: {
      type: Boolean,
      default: false,
    },
    selectedPackage: {
      type: Object,
      default: () => ({
        name: 'premium_package',
      }),
    },
    timingDialog: {
      type: Boolean,
      default: false,
    },
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    whatsAppDialog: {
      type: Boolean,
      default: false,
    },
    eventDetailsDialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const checkboxStartInvitationDate = ref(false)
    const checkboxMessageTemplate = ref(false)
    const checkboxCallTemplate = ref(false)
    const confirmationMsg = ref(false)
    const confirmStartInvitationDate = ref(false)

    const dialogOpen = computed({
      get: () => props.isDialogOpenBeforePay,
      set: value => emit('update:isDialogOpenBeforePay', value),
    })

    // WhatsApp dialog
    const whatsAppDialogHandler = computed({
      get: () => props.whatsAppDialog,
      set: value => emit('update:whatsAppDialog', value),
    })

    // Event details dialog
    const eventDetailsDialogHandler = computed({
      get: () => props.eventDetailsDialog,
      set: value => emit('update:eventDetailsDialog', value),
    })

    // Event details dialog
    const timingDialogHandler = computed({
      get: () => props.timingDialog,
      set: value => emit('update:timingDialog', value),
    })

    const saveTiming = () => {
      timingDialogHandler.value = false

      // check if selected package with whatsApp
      if (props.selectedPackage.selectedRadioGroup === 3) {
        whatsAppDialogHandler.value = true
      } else if (props.isUpgrade) {
        emit('validationIsOk', true)
      } else {
        eventDetailsDialogHandler.value = true
      }
    }

    const confirmWhatsApp = () => {
      whatsAppDialogHandler.value = false

      if (!props.isUpgrade) {
        eventDetailsDialogHandler.value = true
      } else {
        emit('validationIsOk', true)
      }
    }

    const validateUserConfirm = () => {
      loading.value = true

      // check the must field: start invitation date and msg template
      if (checkboxStartInvitationDate.value && checkboxMessageTemplate.value) {
        // if selected package with call option, check user confirm
        if (props.selectedPackage.name === 'premium_package') {
          if (checkboxCallTemplate.value) {
            confirmationMsg.value = false
            dialogOpen.value = false
            emit('validationIsOk', true)
          } else {
            confirmationMsg.value = true
          }
        } else {
          confirmationMsg.value = false
          dialogOpen.value = false
          emit('validationIsOk', true)
        }
      } else {
        confirmationMsg.value = true
      }
      loading.value = false
    }
    const targetLanguage = ref(i18n.locale)

    const computedWhatsAppTemplate = computed(() => {
      // eslint-disable-next-line operator-linebreak
      targetLanguage.value =
        store.state.eventData.languages.filter(el => el.lang === i18n.locale).length > 0
          ? i18n.locale
          : store.state.eventData.defaultLang

      const eventName = store.state.eventData.names[targetLanguage.value]

      const translatedTemplate = i18n.t('WhatsAppTemplate', targetLanguage.value, { eventName })

      return translatedTemplate
    })

    return {
      dialogOpen,
      loading,
      checkboxStartInvitationDate,
      checkboxMessageTemplate,
      checkboxCallTemplate,
      moment,
      confirmationMsg,
      validateUserConfirm,
      saveTiming,
      confirmStartInvitationDate,
      computedWhatsAppTemplate,
      targetLanguage,
      whatsAppDialogHandler,
      eventDetailsDialogHandler,
      timingDialogHandler,
      confirmWhatsApp,
    }
  },
}
</script>
